const getUrlParams = require('../../utils/getUrlParams')

module.exports = class TitleBanner {
  constructor () {
    const isSearchPage = document.location.href.includes('/search')
    this.context = getUrlParams('context')

    this.elements = {
      banner: document.querySelector('.title-banner'),
      header: document.querySelector('.title-banner h1'),
      companyImage: document.querySelector('.title-banner').getAttribute('data-company-img'),
      companyText: document.querySelector('.title-banner').getAttribute('data-company-text'),
      schoolImage: document.querySelector('.title-banner').getAttribute('data-school-img'),
      schoolText: document.querySelector('.title-banner').getAttribute('data-school-text')
    }

    this.callbacks = {
      render: context => this.render(context),
      onContextChange: e => this.onContextChange(e)
    }

    isSearchPage && this.callbacks.render(this.context)
    isSearchPage && document.addEventListener('context-change', this.callbacks.onContextChange)
  }

  render (context) {
    const isCompany = context === 'isCompany'

    this.elements.banner.style.backgroundImage = `url(${isCompany ? this.elements.companyImage : this.elements.schoolImage})`
    this.elements.header.innerText = isCompany? this.elements.companyText : this.elements.schoolText
  }

  onContextChange (e) {
    this.callbacks.render(e.detail.context)
  }
}
