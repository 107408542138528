const getOffersHtml = require('../offer-tile/getOffersHtml')
const fetchOffers = require('../../utils/fetchOffers')
const getSearchOptions = require('../../utils/getSearchOptions')
const updateUrl = require('../../utils/updateUrl')

module.exports = class SearchBar {
  constructor () {
    this.elements = {
      contextToggle: document.querySelector('.search-bar .search-context'),
      queryInput: document.querySelector('.search-bar input[name="search-query"]'),
      loadMoreButton: document.querySelector('.load-more a'),
      tagsWrapper: document.querySelector('form.search-bar .search-bar__options'),
      offersList: document.querySelector('.offers-list')
    }

    this.callbacks = {
      render: (props) => this.render(props),
      loadMore: (e) => this.loadMore(e)
    }

    this.elements.loadMoreButton.addEventListener('click', this.callbacks.loadMore)
    this.startPage = 1
  }

  loadMore (e) {
    e.preventDefault()
    this.startPage++
    this.callbacks.render()
  }

  async render () {
    const options = getSearchOptions({
      page: this.startPage,
      query: this.elements.queryInput.value,
      context: this.elements.contextToggle.value,
      tagsList: this.elements.tagsWrapper.querySelectorAll('input')
    })

    const offers = await fetchOffers(options)

    if (!offers.pagination.has_next_page) this.elements.loadMoreButton.classList.add('hidden')
    updateUrl('page', offers.pagination.current_page)

    this.elements.offersList.insertAdjacentHTML('beforeend', getOffersHtml(offers.items))
  }
}
