const parseTags = require('./parseTags')

module.exports = ({ query = '', tagsList, limit, page = 1}) => {
  // const urlParams = new URLSearchParams(window.location.search)
  const tags = parseTags(tagsList)
  limit = 'limit=20'
  page = `page=${page}`

  return `q=${query}${tags ? `&${tags}` : ''}&${limit}&${page}`
}
