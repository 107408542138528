const getOffersHtml = require('../offer-tile/getOffersHtml')
const getTagsHtml = require('../search-bar/getTagsHtml')
const fetchOffers = require('../../utils/fetchOffers')
const fetchTags = require('../../utils/fetchTags')
const getSearchOptions = require('../../utils/getSearchOptions')
const updateUrl = require('../../utils/updateUrl')

module.exports = class SearchBar {
  constructor () {
    this.elements = {
      searchForm: document.querySelector('form.search-bar'),
      contextToggle: document.querySelector('form.search-bar .search-context'),
      queryInput: document.querySelector('form.search-bar input[name="search-query"]'),
      submitButton: document.querySelector('form.search-bar .search-button'),
      tagsWrapper: document.querySelector('form.search-bar .search-bar__options'),
      offersList: document.querySelector('.offers-list'),
      loadMoreButton: document.querySelector('.load-more a'),
      offersCounterWrapper: document.querySelector('.offers-counter')
    }

    this.callbacks = {
      render: () => this.render(),
      renderOffers: (e) => this.renderOffers(e),
      onContextChange: () => this.onContextChange(),
      onTagsChange: (e) => this.onTagsChange(e)
    }

    this.elements.contextToggle.addEventListener('change', this.callbacks.onContextChange)
    this.elements.searchForm.addEventListener('submit', this.callbacks.renderOffers)
    this.elements.submitButton.addEventListener('click', this.callbacks.renderOffers)
    this.elements.tagsWrapper.addEventListener('click', this.callbacks.onTagsChange)

    this.callbacks.render()
  }

  async render () {
    this.handleQuery()
    this.handleContext()
    await this.renderTags()
    await this.renderOffers()
  }

  async renderTags () {
    const tags = await fetchTags()
    const context = this.elements.contextToggle.value

    this.elements.tagsWrapper.innerHTML = getTagsHtml(tags, context)
  }

  async renderOffers (e) {
    e && e.preventDefault()

    const options = getSearchOptions({
      query: this.elements.queryInput.value,
      tagsList: this.elements.tagsWrapper.querySelectorAll('input')
    })

    console.log(options)

    const offers = await fetchOffers(options)

    this.elements.offersCounterWrapper.innerHTML = `(<span>${offers.pagination.total_items}</span> ofert dla podanych kryteriów)`
    if (offers.pagination.has_next_page) {
      this.elements.loadMoreButton.classList.remove('hidden')
    } else {
      this.elements.loadMoreButton.classList.add('hidden')
    }

    this.elements.queryInput.value && updateUrl('query', this.elements.queryInput.value)

    this.elements.offersList.innerHTML = getOffersHtml(offers.items)
  }

  async onContextChange () {
    const context = this.elements.contextToggle.value
    updateUrl('context', context)
    document.dispatchEvent(new CustomEvent('context-change', { detail: { context }}))
    await this.renderTags()
    await this.renderOffers()
  }

  async onTagsChange (e) {
    if (e.target.type !== 'checkbox') return
    const tags = this.getTags()
    updateUrl('tags', tags)
    await this.renderOffers()
  }

  getTags () {
    const tags = [].slice.call(this.elements.tagsWrapper.querySelectorAll('input:checked'))
    return tags.map(el => el.value).join(',')
  }

  handleContext (e) {
    const urlParams = new URLSearchParams(window.location.search)
    let context = urlParams.get('context')

    if (context !== 'isSchool' && context !== 'isCompany') context = this.elements.contextToggle.value

    const contextChanged = urlParams.get('context') !== this.elements.contextToggle.value
    if (contextChanged) {
      this.elements.contextToggle.value = context
      updateUrl('context', context)
    }
  }

  handleQuery () {
    const params = new URLSearchParams(window.location.search)
    const query = params.get('query')

    if (!query) return
    this.elements.queryInput.value = query
  }
}
