const getUrlParams = require('../../utils/getUrlParams')

module.exports = (tags, context) => {
  const urlTags = getUrlParams('tags') || ''
  const tagsForContext = tags.filter(el => el[context])
  let checkboxes = ''

  tagsForContext.forEach(tag => {
    checkboxes += `
    <div>
      <input type="checkbox" id="${tag.name.replace(/\s/g,'')}" name="${tag.name}" value="${tag.id}" ${urlTags.includes(tag.id) && 'checked'}>
      <label for="${tag.name.replace(/\s/g,'')}">${tag.name}</label>
    </div>
    `
  })

return `
  <fieldset>
    ${checkboxes}
  </fieldset>
  `
}
