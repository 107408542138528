module.exports = class MainMenu {
  constructor () {
    this.elements = {
      body: document.querySelector('body'),
      mainMenu: document.querySelector('.main-menu'),
      mainMenuList: document.querySelector('.main-menu .main-menu__list'),
      menuTrigger: document.querySelector('.main-menu .main-menu__trigger'),
      closeMenuTrigger: document.querySelector('.main-menu .main-menu__close-trigger'),
    }

    this.callbacks = {
      onOpen: (e) => this.onOpen(e),
      onClose: (e) => this.onClose(e)
    }

    this.elements.mainMenuList.style.top = '-100%'

    this.elements.menuTrigger.addEventListener('click', this.callbacks.onOpen)
    this.elements.closeMenuTrigger.addEventListener('click', this.callbacks.onClose)
  }

  onOpen (e) {
    e.preventDefault()
    this.elements.body.classList.add('modal-open')

    this.elements.mainMenu.classList.add('main-menu--popup')
    setTimeout(() => {
      this.elements.mainMenuList.style.top = '0%'
    }, 10)
  }
  onClose (e) {
    e.preventDefault()
    this.elements.mainMenuList.style.top = '-100%'

    setTimeout(() => {
      this.elements.mainMenu.classList.remove('main-menu--popup')
      this.elements.body.classList.remove('modal-open')
    }, 260)
  }
}
