const SearchBar = require('./components/search-bar')
const LoadMore = require('./components/load-more')
const MainMenu = require('./components/main-menu')
const TitleBanner = require('./components/title-banner')

new MainMenu()
new TitleBanner()

if (document.querySelector('form.search-bar')) {
  new SearchBar()
  new LoadMore()
}

