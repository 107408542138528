module.exports = (offers) => {
  let template = ''
  offers.forEach( offer => {
    template += `
    <div class="offer-tile">
      <a href="${ offer.url }">
        <img src="${ offer.image }">
        <h3>${ offer.title }</h3>
        <span>${ offer.description }</span>
      </a>
    </div>
    `
  })

  return template
}
