module.exports = function fetchOffers (options) {
  // TODO - change prefix
  const prefix = window.location.hostname === 'localhost' ? 'http://www2.travel-partner.pl' : ''

  return fetch(`${prefix}/ajax/offers?${options}`,  { method: 'GET' })
    .then(response => response.json())
    .then(data => {
      data.items.forEach(el => {
        const description = el.description
          .replace(/\r?\n|\r/g, ' ')
          .replace(/^\s+|\s+$/g, ' ')
          .trim()
          .substring(0, 200)

        el.image = `${prefix}${el.image}`
        el.description = `${description}...`
      })

      return data
    })
    .catch(e => console.log(e))
}
